import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const GridContent = props => {
    return (
        <div className="container flex flex-col gap-10">
            {props.data?.map((item, idx) => (
                <div
                    key={idx}
                    className={`flex flex-wrap items-center justify-between lg:flex-nowrap ${
                        idx % 2 === 0 ? "lg:flex-row-reverse" : "lg:flex-row"
                    }`}
                >
                    <div className="w-full lg:w-1/2">
                        <img
                            src={item?.image}
                            className={`${
                                idx % 2 === 0 ? "ml-auto" : "mr-auto"
                            } rounded-[20px] object-cover lg:h-[475px] lg:w-9/12 mb-5 lg:mb-0`}
                        />
                    </div>
                    <div className="flex w-full flex-col gap-5 lg:w-1/2">
                        <p className="font-bold lg:text-2xl">{item?.title}</p>
                        <p>{item?.description}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default GridContent
