import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import gsap from "gsap"
import ScrollTrigger from "gsap/dist/ScrollTrigger"
import React, { useEffect, useRef } from "react"
import { caseStudies } from "."
import ShowCase from "../../components/CaseStudy/CaseStudyShowCase"
import MandalaBanner from "../../components/CaseStudy/MandalaBanner"
import {
    MandalaImageFive,
    MandalaImageFour,
    MandalaImageOne,
    MandalaImageThree,
    MandalaImageTwo
} from "../../components/CaseStudy/MandalaImage"
import Overview from "../../components/CaseStudy/Overview"
import ContentImage from "../../components/ContentImage/ContentImage"
import Newsletter from "../../components/Form/Newsletter"
import Hello from "../../components/Hello/Hello"
import HeroTwo from "../../components/Hero/HeroTwo"
import Layout from "../../components/layout"
import { SEO } from "../../components/seo"
import StaticTestimonial from "../../components/Testimonial/StaticTesimonial"
import TitleContent from "../../components/TitleContent/TitleContent"
import "./case-studies.scss"
import GridContent from "../../components/GridContent"
import GridContentCard from "../../components/GridContentCard"

if (typeof window !== "undefined") {
    gsap.registerPlugin(ScrollTrigger)
}

const techs = [
    { src: "/case-study/next.svg" },
    { src: "/case-study/sanity.png" },
    { src: "/case-study/netlify.svg" },
    { src: "/case-study/react.svg" },
    { src: "/case-study/gsap.png" }
]

const sliderImages = [
    {
        src: "/case-study/mandala-slider-1.webp",
        text: "We solve economic, policy and regulatory problems",
        component: MandalaImageOne
    },
    {
        src: "/case-study/mandala-slider-2.webp",
        text: "We use novel data and strategy for the changing world",
        component: MandalaImageTwo
    },
    {
        src: "/case-study/mandala-slider-3.webp",
        text: "AI Human Capital Investment Index",
        component: MandalaImageThree
    },
    {
        src: "/case-study/mandala-slider-4.webp",
        text: "Proven strategy consulting methods",
        component: MandalaImageFour
    },
    {
        src: "/case-study/mandala-slider-5.webp",
        text: "Expert advice in regulatory and policy strategy",
        component: MandalaImageFive
    }
]

const overview = {
    overview:
        "Purple Horse wanted to increase their user base (muses registrations) into Vietnam. A challenge we were ready to undertake and very much excited about it. They needed a clear rebranding strategy in to a new and dynamic market. Saigon Digital's mission was clear - to enhance Purple Horse's brand awareness and boost lead generation through insightful and tailored strategies.",
    services: ["Marketing", "Design", "SEO"]
    // linkUrl: "https://mandalapartners.com/"
}

const phImageContents = [
    {
        title: "Research and Insights",
        description: `Saigon Digital began with market research, a persona-driven approach, delving into the cultural nuances, preferences, and digital habits of Vietnamese Gen Z. This helped understand the gap between Purple Horse's current branding and the audience's expectations. We mapped out the persona journey to make sure every touchpoint and message was related and drove interest to the target audience.`,
        image: "/case-study/PH-Outcome - 1.png"
    },
    {
        title: "Rebranding for Resonance",
        description: `Acknowledging the need for a visual and verbal guide, Saigon Digital created a new social media guide. This guide included detailed verbal and visual guidelines to ensure that every piece of content was cohesive, appealing, and resonant with the youthful audience. We incorporated Purple Horse’s purple with a more balanced and relatable colour palette.`,
        image: "/case-study/PH-Outcome - 2.png"
    },
    {
        title: "Enhancing Social Media Engagement",
        description: `Understanding that social media is more than a platform for consumption but a space for interaction and self-expression among Gen Z, Saigon Digital optimised the brand's visual language. The strategy involved using the purple hue as a dynamic, yet approachable element, complemented by a wider colour palette that was both bold and relatable.`,
        image: "/case-study/PH-Outcome - 3.png"
    },
    {
        title: "Engagement-Driven Content",
        description: `With the user-centric approach in place, Saigon Digital produced and curated a series of engaging content pieces. Each post, story, and ad was designed to not only attract but also retain and engage the target audience, encouraging interaction, and driving the action of registrations.`,
        image: "/case-study/PH-Outcome - 4.png"
    },
    {
        title: "Targeted & Retargeted Campaigns",
        description: `Utilising the insights from the research phase, targeted PPC campaigns and dynamic social media management were implemented to maximise reach and engagement among the specific audience segments identified. Besides, we used the retargeted PPC campaigns to boost the awareness of existing muses (current members at Van Hien University) and aligned with the target audience to expand the lead generation.`,
        image: "/case-study/PH-Outcome - 5.png"
    }
]

const gridContentCards = [
    {
        title: "Consistent and Relatable Branding",
        description:
            "The new, consistent branding and adjusted visual strategy were critical in capturing and sustaining the attention and interest of the target audience.",
        image: "/case-study/PH-2 col - 1.png"
    },
    {
        title: "Understanding Your Audience",
        description:
            "The importance of aligning brand visuals and messaging with the audience's preferences was clearly demonstrated. The research-driven approach allowed Saigon Digital to tailor the campaign effectively.",
        image: "/case-study/PH-2 col - 2.png"
    },
    {
        title: "Engagement and Conversion",
        description:
            "A strategic mix of compelling visuals and aligned targeted & retargeted messaging led to high engagement and conversion rates, surpassing industry averages and campaign targets.",
        image: "/case-study/PH-2 col - 3.png"
    }
]

const tlDefaults = {
    ease: "slow.inOut",
    duration: 1.25
}

const PurpleHorseDetail = ({ location }) => {
    const $sliderSection = useRef(null)
    const $pageWrapper = useRef(null)
    const $slider = useRef(null)
    const $progress = useRef(null)
    const $contentImageWrapper = useRef(null)
    const $contentImagePin = useRef(null)

    const currentSlide = useRef(undefined)
    const next = useRef(0)
    const alreadyEntered = useRef(false)

    const heroData = {
        preheader: "Purple Horse",
        title: "Behind the scenes of Purple Horse"
    }
    const context = {
        pageName: "SD | Case studies - Purple Horse",
        pageUri: location.href
    }

    useEffect(() => {
        const ctx = gsap.context(() => {
            const outers = gsap.utils.toArray(".slider-outer")
            const inners = gsap.utils.toArray(".slider-inner")
            const images = gsap.utils.toArray(".slider-image")

            gsap.set(outers, { yPercent: 100 })
            gsap.set(inners, { yPercent: -100 })
            gsap.set(images, { scale: 1.3, yPercent: 15 })
            gsap.set($progress.current, { scaleX: 0 })

            ScrollTrigger.create({
                trigger: $sliderSection.current,
                start: "top top",
                // markers: true,
                onEnter: () => {
                    if (!alreadyEntered.current) {
                        slideIn()
                    }

                    alreadyEntered.current = true
                }
            })
        })

        return () => {
            ctx.revert()
        }
    }, [])

    // useEffect(() => {
    //     const mm = gsap.matchMedia()

    //     mm.add("(min-width: 1024px)", () => {
    //         const contentImages =
    //             $contentImageWrapper.current.querySelectorAll(".content-image")

    //         contentImages[0].classList.add("is-active")

    //         ScrollTrigger.create({
    //             trigger: $contentImageWrapper.current,
    //             // pin: $contentImagePin.current,
    //             // pinSpacer: false,
    //             start: "top top",
    //             // end: `+=${contentImages.length * 100}% bottom`,
    //             // markers: true,
    //             onLeave: () => {
    //                 contentImages[0].classList.remove("is-active")
    //                 contentImages[contentImages.length - 1].classList.add(
    //                     "is-active"
    //                 )
    //             },
    //             onUpdate: self => {
    //                 const progress = Number(self.progress.toFixed(3))
    //                 const total = contentImages.length + 1

    //                 contentImages.forEach((_, i) => {
    //                     const oldIndex = i
    //                     const newIndex = oldIndex + 1

    //                     if (
    //                         progress > oldIndex / total &&
    //                         progress < newIndex / total
    //                     ) {
    //                         contentImages[oldIndex].classList.add("is-active")

    //                         if (self.direction === 1 && oldIndex > 0) {
    //                             contentImages[oldIndex - 1].classList.remove(
    //                                 "is-active"
    //                             )
    //                         } else if (
    //                             self.direction === -1 &&
    //                             newIndex < contentImages.length
    //                         ) {
    //                             contentImages[newIndex].classList.remove(
    //                                 "is-active"
    //                             )
    //                         }
    //                     }
    //                 })
    //             }
    //         })
    //     })

    //     return () => {
    //         mm.revert()
    //     }
    // }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentSlide.current !== undefined) {
            }
        }, 4000)

        return () => {
            clearInterval(interval)
        }
    }, [])

    // Slides a section in on scroll down
    function slideIn() {
        const outers = gsap.utils.toArray(".slider-outer")
        const inners = gsap.utils.toArray(".slider-inner")
        const images = gsap.utils.toArray(".slider-image")
        const texts = gsap.utils.toArray(".slider-text")

        // The first time this function runs, currentSlide is undefined
        const tl = gsap.timeline({
            paused: true,
            defaults: tlDefaults,
            onComplete: () => {
                currentSlide.current = next.current
                next.current = currentSlide.current + 1
                if (next.current > sliderImages.length - 1) {
                    currentSlide.current = sliderImages.length - 1
                    next.current = 0
                }

                const timeout = setTimeout(() => {
                    slideIn()
                }, 2000)
            }
        })

        tl.to(outers[next.current], { yPercent: 0 }, 0)
            .to(inners[next.current], { yPercent: 0 }, 0)
            .to(images[next?.current], { yPercent: 0, scale: 1 }, 0)
            .to(
                $progress.current,
                { scaleX: (next.current + 1) / sliderImages.length },
                0
            )
            .fromTo(
                texts[next?.current],
                { y: 40, opacity: 0 },
                { y: 0, opacity: 1 },
                0
            )

        if (currentSlide?.current !== undefined) {
            tl.to(outers[currentSlide.current], { yPercent: -100 }, 0)
                .to(inners[currentSlide.current], { yPercent: 100 }, 0)
                .to(
                    images[currentSlide.current],
                    { scale: 1.2, yPercent: -15 },
                    0
                )
                .to(
                    texts[currentSlide?.current],
                    { y: -40, opacity: 0, duration: 0.8 },
                    0
                )

            tl.add(
                gsap
                    .timeline()
                    .set(outers[currentSlide?.current], {
                        yPercent: 100
                    })
                    .set(inners[currentSlide?.current], {
                        yPercent: -100
                    })
                    .set(images[currentSlide?.current], {
                        yPercent: 15,
                        scale: 1.3
                    })
            )
        }

        tl.play(0)
    }

    return (
        <div ref={$pageWrapper}>
            <Layout context={context}>
                <HeroTwo props={heroData} />
                {/* Overview */}
                <Overview {...overview} />
                {/* banner */}
                {/* <MandalaBanner className={"mt-20 lg:mt-24"}>
                    <StaticImage
                        src={"../../../static/case-study/PH-top banner.png"}
                        alt="purple horse"
                        objectFit="cover"
                        className="h-full w-full"
                    />
                </MandalaBanner> */}
                <StaticImage
                    src={"../../../static/case-study/PH-top-banner.png"}
                    alt="purple horse"
                    objectFit="cover"
                    className="mt-20 h-full w-full lg:mt-24"
                />
                <TitleContent
                    title="Purple Horse entered the market with two significant challenges:"
                    className="py-14 lg:py-24"
                >
                    <ul className="ml-4 flex list-disc flex-col lg:ml-0 lg:gap-10">
                        <li>
                            <span className="font-bold">
                                Brand Recognition:
                            </span>{" "}
                            There was a general lack of awareness about Purple
                            Horse. Their social media engagement was minimal,
                            indicating that the brand was not yet on the radar
                            of their target audience.
                        </li>
                        <li>
                            <span className="font-bold">
                                Refining Visual Strategy:
                            </span>{" "}
                            The brand’s signature purple, synonymous with
                            creativity and innovation, required adaptation to
                            appeal to Vietnam's Gen Z, a group that values
                            vibrant and diverse visual experiences.
                        </li>
                    </ul>
                </TitleContent>
                <div className="flex flex-col gap-10 bg-cultured py-20 lg:py-24">
                    <TitleContent
                        title={"Approach and Execution"}
                    ></TitleContent>
                    <div className="lg:mt-20">
                        <GridContent data={phImageContents} />
                    </div>
                </div>
                <StaticImage
                    src={"../../../static/case-study/PH - slide - 1.png"}
                    alt="purple horse"
                    objectFit="cover"
                    className="h-full w-full object-cover"
                />
                <TitleContent
                    title="Detailed Analysis of Results"
                    className="py-14 lg:py-24"
                >
                    <p>
                        <span className="font-bold">
                            1. Visual and Brand Transformation:
                        </span>
                        <ul className="ml-5 flex list-disc flex-col">
                            <li>
                                Before: Inconsistent branding and strong purple
                                visuals that didn't engage the target
                                demographic.
                            </li>
                            <li>
                                After: A cohesive, appealing brand presence
                                tailored to resonate with the Gen Z audience in
                                Vietnam. The transformation led to{" "}
                                <span className="font-semibold text-primary-color">
                                    increased brand recognition
                                </span>{" "}
                                and a{" "}
                                <span className="font-semibold text-primary-color">
                                    more visually appealing identity
                                </span>{" "}
                                across platforms.
                            </li>
                        </ul>
                    </p>
                    <p>
                        <span className="font-bold">
                            2. Detailed Campaign Metrics:
                        </span>
                        <ul className="ml-5 flex list-disc flex-col">
                            <li>
                                CTR and Engagement: The campaign saw an{" "}
                                <span className="font-semibold text-primary-color">
                                    overall CTR of 2.17%
                                </span>
                                , with specific strategies like 'Click to Web'
                                and 'Web Lead' driving substantial traffic and
                                engagement. This was a direct result of the more
                                targeted and appealing visual and verbal
                                content.
                            </li>
                            <li>
                                After: A cohesive, appealing brand presence
                                tailored to resonate with the Gen Z audience in
                                Vietnam. The transformation led to{" "}
                                <span className="font-semibold text-primary-color">
                                    increased brand recognition
                                </span>{" "}
                                and a{" "}
                                <span className="font-semibold text-primary-color">
                                    more visually appealing identity
                                </span>{" "}
                                across platforms.
                            </li>
                        </ul>
                    </p>
                </TitleContent>
                <GridContentCard data={gridContentCards} />
                <div className="my-20">
                    <Newsletter context={context} />
                </div>
                <TitleContent title={"Conclusion"}>
                    <p>
                        The collaboration between Purple Horse and Saigon
                        Digital highlights the power of strategic rebranding and
                        targeted marketing. By understanding and overcoming
                        initial challenges, including brand recognition and
                        visual disconnects, Saigon Digital helped Purple Horse
                        not just increase the muse registrations but make a
                        significant impact in the Vietnamese market.
                    </p>
                </TitleContent>
                <StaticImage
                    src={"../../../static/case-study/PH - Result.png"}
                    alt="purple horse"
                    objectFit="cover"
                    className="mt-20 h-full w-full lg:mt-24"
                />
                <StaticTestimonial
                    title={"Thoughts from Purple Horse"}
                    content={
                        <ul className="flex flex-col gap-4">
                            <li>
                                Meeting with Saigon Digital was totally by
                                chance when I searched for “Trustful” digital
                                marketing agencies.
                            </li>
                            <li>
                                It was my first time hiring a marketing agency
                                in Vietnam. During the time we worked with
                                Saigon Digital for the 3 months, the performance
                                and delivery exceeded my expectations - the
                                communication was concise and clear from the
                                beginning. 
                            </li>
                            <li>
                                Saigon Digital was creative and proactive. I was
                                extremely satisfied with their professional
                                online branding guidelines that they produced
                                and suggestions to attract more leads in view of
                                the local consumers. 
                            </li>
                            <li>
                                I will keep working with Saigon Digital
                                afterwards, and I’m willing to recommend Saigon
                                Digital to my clients as well because I value
                                “Communication” very highly. This was one of the
                                key reasons the project was successful, and I
                                would recommend them again in the future.
                            </li>
                        </ul>
                    }
                    author={"Jinny Lee"}
                    position="CEO at Purple Horse"
                />
                <ShowCase data={caseStudies} />
            </Layout>
        </div>
    )
}

export default PurpleHorseDetail

export const Head = () => (
    <SEO
        title={"Purple Horse | Saigon Digital"}
        description={
            "Purple horse's brand-new website is built with tech stack, frontend Next.js and backend Sanity CMS. Saigon Digital focus on creating a responsive and user-friendly UI for website development."
        }
        keywords={
            "web design saigon, web development saigon, jamstack development, jamstack web development saigon"
        }
    />
)
