import React from "react"

const GridContentCard = props => {
    return (
        <div className="container grid lg:gap-24 gap-16 lg:grid-cols-2">
            {props.data?.map(item => (
                <div className="flex flex-col gap-4">
                    <div className="relative rounded-[10px]">
                        <img className="w-full h-full object-cover" src={item?.image} alt={item?.title} />
                    </div>
                    <div>
                        <p className="text-[21px] font-bold">{item?.title}</p>
                    </div>
                    <div>
                        <p>{item?.description}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default GridContentCard
